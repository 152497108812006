import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import {
  airtableFieldConstruction,
  filterAirtableConstructor,
  normalizeString,
  subtractNumber,
} from '@/utils/general';
import {Testimony, TestimonyManager} from '@/utils/testimony';

const {
  VUE_APP_BEARER,
  VUE_APP_AIRTABLE_WORKSPACE,
  VUE_APP_TOKEN_FACEBOOK,
  VUE_APP_INSTAGRAM_USER_ID,
  VUE_APP_HASHTAG_ID,
  VUE_APP_INSTAGRAM_AIRTABLE_ID,
  VUE_APP_FACEBOOK_AIRTABLE_ID,
  VUE_APP_WEB_PAGE_AIRTABLE_ID,
  VUE_APP_HASURA_URL,
  VUE_APP_SELL_PROD_WEBHOOK_URL,
  VUE_APP_BUY_PROD_WEBHOOK_URL,
  VUE_APP_RENEWAL_PROD_WEBHOOK_URL,
  VUE_APP_PROD_WEBHOOK_BEARER,
} = process.env;
Vue.use(Vuex);

const CONFIG = {
  headers: {
    Authorization: VUE_APP_BEARER,
  },
};

function addPrices(numbers) {
  const min = Math.min(...numbers);
  const max = Math.max(...numbers);
  const all = [];

  for (let i = min; i <= max; i += 1000000) {
    all.push(i);
  }
  return all;
}

export function splitName(name) {
  const words = name.split(' ');

  let nombres, apellidos;

  if (words.length === 2) {
    [nombres, apellidos] = words;
  } else {
    nombres = words.slice(0, 2).join(' ');
    apellidos = words.slice(2).join(' ');
  }

  return {nombres, apellidos};
}

function sortArray(itemArray, prop, asc = true) {
  return itemArray.sort((a, b) => {
    if (asc) {
      if (a[prop] > b[prop]) return 1;
      if (a[prop] < b[prop]) return -1;
    } else {
      if (a[prop] < b[prop]) return 1;
      if (a[prop] > b[prop]) return -1;
    }
    return 0;
  });
}

function filter(array) {
  return array.filter(function (valor, indiceActual, arreglo) {
    let indiceAlBuscar = arreglo.indexOf(valor);
    return indiceActual === indiceAlBuscar;
  });
}

function search(itemArray, search, prop) {
  return itemArray.filter(
    (item) => item.datos[prop].toUpperCase() === search.toUpperCase()
  );
}

function mapArray(itemArray, prop) {
  return itemArray.map((item) =>
    typeof item.datos[prop] === String
      ? item.datos[prop].toUpperCase()
      : item.datos[prop]
  );
}

function formatVehiculo(itemArray) {
  return sortArray(
    itemArray.map((vehiculo) => {
      return {
        precio: vehiculo.datos.Precio_de_Venta,
        pie: parseInt(vehiculo.datos.Precio_de_Venta) * 0.4,
        pieCredit: parseInt(vehiculo.datos.Precio_de_Venta) * 0.4,
        ppu: vehiculo.datos.patente,
        titulo:
          vehiculo.datos.Marca +
          ' ' +
          vehiculo.datos.Modelo +
          ' ' +
          vehiculo.datos.Ano,
        year: vehiculo.datos.Ano,
        version: vehiculo.datos.Version,
        subtitulo:
          vehiculo.datos.Combustible +
          ' ' +
          vehiculo.datos.Transmision +
          ' ' +
          vehiculo.datos.traccion,
        kilometraje: vehiculo.datos.Kilometraje,
        foto: vehiculo.fotos.value
          ? vehiculo.fotos.value
          : {url: require('@/assets/img.jpg')},
        id: vehiculo.id,
        linkBase: vehiculo.datos.linkBase,
        status: vehiculo.datos.status,
      };
    }),
    'titulo'
  );
}

function findItem(itemArray, prop, range) {
  //Precio_de_Venta
  //Ano

  return itemArray.filter((vehiculo) => {
    if (
      vehiculo.datos[prop] >= range.min &&
      vehiculo.datos[prop] <= range.max
    ) {
      return vehiculo;
    }
  });
}

function getPrices(itemArray) {
  let precios = itemArray
    .map((item) => item.datos.Precio_de_Venta)
    .sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });

  precios.push(getNextNumber(precios[precios.length - 1]));
  return filter(precios.map((item) => subtractNumber(item)).filter((_) => _));
}

function getNextNumber(number) {
  if (!number) return '';
  const value = number.toString().split('');
  const startIndex = value.length === 7 ? 0 : value.length === 8 ? 1 : 2;
  for (let i = 1; i < 10; i++) {
    if (Number(value[startIndex]) === 9) {
      if (startIndex < 1) value.unshift('1');
      else {
        for (let j = 1; j < 10; j++) {
          if (j > Number(value[startIndex - 1])) {
            value[startIndex - 1] = j.toString();
            break;
          }
        }
      }
      break;
    }

    if (i > Number(value[startIndex])) {
      value[startIndex] = i.toString();
      break;
    }
  }

  return subtractNumber(Number(value.join('')), startIndex);
}

export default new Vuex.Store({
  state: {
    photos: null,
    facebookId: VUE_APP_FACEBOOK_AIRTABLE_ID,
    instagramId: VUE_APP_INSTAGRAM_AIRTABLE_ID,
    webPageId: VUE_APP_WEB_PAGE_AIRTABLE_ID,
    canal: '',
    campaign: '',
    scroll: false,
    activeMostrar: false,
    bearer: VUE_APP_BEARER,
    workSpace: VUE_APP_AIRTABLE_WORKSPACE,
    facebookToken: VUE_APP_TOKEN_FACEBOOK,
    userInstagramId: VUE_APP_INSTAGRAM_USER_ID,
    hashtagId: VUE_APP_HASHTAG_ID,
    instagramPost: [],
    data: [],
    marcas: [],
    filterMarcas: [],
    modelos: [],
    anos: [],
    precios: [],
    carros: [],
    stock: [],
    stock2: [],
    versions: [],
    stockGeneral: [],
    categorias: [],
    loading: false,
    equipo_ventas: [],
    clientes: [],
    topClientes: [],
    cliente: {},
    correoSend: false,
    rules: {
      required: (v) => !!v || 'Campo Requerido',
    },
    vehiculo: {},
    allowComment: false,
    IdCliente: null,
    stockLimit: 8,
    personId: null,
    vehicleId: null,
    //--------------
    channel: [],
    executive: undefined,
    evaluationSend: false,
    calificationDone: false,
    drawer: false,
    //---------------
    brands: [],
    models: [],
    versiones: [],
    fuels: [
      {id: 'recnVaISLwXWB6N94', name: 'Bencina'},
      {id: 'recK6jItmWQzfc4CO', name: 'Diesel'},
      {id: 'recjgeoaF9BqBGrOE', name: 'Eléctrico'},
      {id: 'recOHOYVuJ2I0EOL8', name: 'Híbrido'},
    ],
    carsFuels: [],
    transmissions: [],
    tractions: [],
    years: [],
    formTractions: [
      {id: 'recXZMme2hzcinzLV', name: '4X2'},
      {id: 'rec80kjGUYNM3xeU8', name: '4X4'},
    ],
    formTransmition: [
      {id: 'recRLoXCZb80fUwgB', name: 'Manual'},
      {id: 'recjYU6LJ5ws1LANF', name: 'Automatica'},
    ],
    //---------------------
    vehicleDetail: null,
    vehiclePpuDetail: null,
    attributes: [],
    testimonyData: [],
  },
  mutations: {
    SET_TESTIMONY_DATA: (state, val) => (state.testimonyData = val),
    SET_ATTRIBUTES: (state, val) => (state.attributes = val),
    SET_VEHICLE_PPU_DETAIL: (state, val) => (state.vehiclePpuDetail = val),
    SET_VEHICLE_DETAIL: (state, val) => (state.vehicleDetail = val),
    SET_PHOTOS: (state, val) => (state.photos = val),
    SET_YEARS: (state, val) => (state.years = val),
    SET_MODELS: (state, val) => (state.models = val),
    SET_VERSIONES: (state, val) => (state.versiones = val),
    SET_BRANDS: (state, val) => (state.brands = val),
    SET_DRAWER: (state, val) => (state.drawer = val),
    SET_CALIFICATION_ALREADY: (state, val) => (state.calificationDone = val),
    SET_EVALUATION_SEND: (state, val) => (state.evaluationSend = val),
    SET_EXECUTIVE: (state, val) => (state.executive = val),
    SET_CHANNEL: (state, val) => (state.channel = val),
    SET_VEHICLE_ID(state, val) {
      state.vehicleId = val;
    },
    SET_PERSON_ID(state, val) {
      state.personId = val;
    },
    SET_VERSION(state, val) {
      state.versions = val;
    },
    SET_STOCK_GENERAL(state, val) {
      state.stockGeneral = val.sort((a, b) => {
        if (a.datos.titulo > b.datos.titulo) return 1;
        if (a.datos.titulo < b.datos.titulo) return -1;
      });

      state.tractions = filter(mapArray(val, 'traccion'));
      state.transmissions = filter(mapArray(val, 'Transmision'));
      state.carsFuels = filter(mapArray(val, 'Combustible'));
      state.marcas = filter(mapArray(val, 'Marca'));
      state.anos = filter(mapArray(val, 'Ano')).sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      state.precios = getPrices(val)
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        })
        .map((prices) => prices);

      state.precios = addPrices(state.precios);
    },
    SET_VEHICULO(state, val) {
      state.vehiculo = val;
    },
    SET_CORREOSEND(state, val) {
      state.correoSend = val;
    },
    SET_EQUIPOVENTA(state, val) {
      state.equipo_ventas = val;
    },
    SET_CATEGORIAS(state, val) {
      state.categorias = val;
    },
    SET_STOCK(state, val) {
      state.stock = val;
    },
    SET_STOCK2(state, val) {
      state.stock2 = val;
    },
    SET_CARROS(state, val) {
      state.carros = val;
    },
    SET_LOADING(state, val) {
      state.loading = val;
    },
    SET_ANO(state, val) {
      state.anos = val;
    },
    SET_MODELOS(state, val) {
      state.modelos = val;
    },
    SET_MARCAS(state, val) {
      state.marcas = val;
    },
    SET_DATA(state, val) {
      state.data = val;
    },
    SET_ACTIVEMOSTRAR(state, val) {
      state.activeMostrar = val;
    },
    SET_SCROLL(state, val) {
      state.scroll = val;
    },
    setCanal(state, {canal, campaign}) {
      state.canal = canal;
      state.campaign = campaign;
    },
  },
  getters: {
    getTestimonies: (state) => {
      return new TestimonyManager(state.testimonyData);
    },
  },
  actions: {
    FIND_AIRTABLE: async function ({state, commit}) {
      commit('SET_LOADING', true);
      let config = {
        headers: {
          Authorization: state.bearer,
        },
      };
      let offset = '';

      //-------stock--------
      let categorias = [];
      let stock = [];

      const vehicles = [];

      try {
        const {
          data: {business_get_public_vehicles: cars},
        } = await axios.get(`${VUE_APP_HASURA_URL}/api/rest/stock`);
        vehicles.push(...cars);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      let vehiculo = {};
      const updateFoto = [{url: require('@/assets/img.jpg')}];

      for (let item of vehicles) {
        // eslint-disable-next-line no-prototype-builtins
        const isCategoria = item.details.attributes['Carrocería'][0];
        const isMarca = item.details.attributes['Marca'][0];
        // eslint-disable-next-line no-prototype-builtins
        const isFotosExternas = item.details.images.some(
          (img) => img.metadata.perspective === 'external'
        );
        const isFotosPortada = item.details.images.some(
          (img) => img.metadata.default
        );

        const isFotosInternal = item.details.images.some(
          (img) => img.metadata.perspective === 'internal'
        );

        const Marca = item.details.attributes['Marca'][0];
        const Modelo = item.details.attributes['Modelo'][0];
        const Version = item.details.attributes['Version'][0];
        const Ano = item.details.attributes['Año'][0];
        const titulo = `${Marca} ${Modelo} ${Version} ${Ano}`;
        const Categoria = [item.details.attributes['Carrocería'][0]];
        // eslint-disable-next-line no-prototype-builtins
        const isColor = item.details.attributes.hasOwnProperty('Color');
        // eslint-disable-next-line no-prototype-builtins

        if (isCategoria && isMarca) {
          const fixPrecio = parseInt(item.details.price);

          categorias.push(item.details.attributes['Carrocería'][0]);
          vehiculo = {
            id: item.id,
            fotos: isFotosPortada
              ? item.details.images.find((img) => img.metadata.default)
              : updateFoto,
            datos: {
              Ano,
              Marca,
              Modelo,
              Version,
              Categoria,
              Color: isColor
                ? (item.details.attributes['Color'][0] || '').toLowerCase()
                : '',
              unicoDueno: !item.details.attributes['Nº de dueños'][0].includes(
                'Único dueño'
              )
                ? 'No'
                : 'Sí',
              Transmision: item.details.attributes['Transmisión'][0],
              Combustible: item.details.attributes['Combustible'][0],
              traccion: item.details.attributes['Tracción'][0],
              Kilometraje: item.details.attributes['Kilometraje'][0],
              doors: item.details.attributes['Nº de puertas'][0],
              speed: item.details.attributes['Nº de velocidades']
                ? item.details.attributes['Nº de velocidades'][0]
                : null,
              power: item.details.attributes['Potencia']
                ? item.details.attributes['Potencia'][0]
                : null,
              Precio_de_Venta: fixPrecio,
              Foto_portada: isFotosPortada
                ? item.details.images.find((img) => img.metadata.default)
                : updateFoto,
              Fotos_Externas: isFotosExternas
                ? item.details.images
                    .filter((img) => img.metadata.perspective === 'external')
                    .map((img) => img.value)
                : [],
              Fotos_internas: isFotosInternal
                ? item.details.images
                    .filter((img) => img.metadata.perspective === 'internal')
                    .map((img) => img.value)
                : [],
              video: item.details.videos.sort((v) =>
                v.metadata.perspective === 'external' ? -1 : 1
              ),
              equipamiento_basico:
                item.details.attributes['Equipamiento básico'] || [],
              equipamiento_comfort:
                item.details.attributes['Equipamiento comfort'] || [],
              equipamiento_seguridad:
                item.details.attributes['Equipamiento seguridad'] || [],
              equipamiento_exterior:
                item.details.attributes['Equipamiento exterior'] || [],
              status: item.details.status,
              titulo,
              tituloPrecio: titulo + ' - ' + fixPrecio,
            },
          };
          stock.push(vehiculo);
        }
      }

      stock = filter(stock);
      categorias = filter(categorias);

      commit('SET_STOCK_GENERAL', stock);
      commit('SET_STOCK2', sortArray(formatVehiculo(stock), 'titulo'));
      commit('SET_CATEGORIAS', categorias.sort());

      let stockcito = [];

      for (let i = 0; i < state.stock2.length; i = i + 2) {
        if (stockcito.length < state.stockLimit) {
          stockcito.push([state.stock2[i], state.stock2[i + 1]]);
        }
      }

      commit('SET_STOCK', stockcito);

      //-------equipo--------
      let equipo = [];
      let equipos = [];

      do {
        await axios
          .get(
            `https://api.airtable.com/v0/${state.workSpace}/Petersen%20-%20Equipo`,
            config
          )
          .then((response) => {
            equipo = response.data.records;

            if (response.data.offset) offset = response.data.offset;
            else offset = '';

            const pos = equipo.findIndex((item) => !item.fields['nombre']);
            if (pos !== -1) equipo.splice(pos, 1);
            for (let item of equipo) {
              item.fields.cargo = item.fields.cargo
                .split(',')
                .map((cargo) => cargo.trim());
              equipos.push(item.fields);
            }

            commit(
              'SET_EQUIPOVENTA',
              equipos.sort(() => Math.random() - 0.5)
            );
          });
      } while (offset !== '');

      commit('SET_LOADING', false);
    },
    FIND_CARS: function ({state, commit}, busqueda) {
      if (!busqueda) return;
      let carros = state.stockGeneral;

      if (busqueda.categoria) {
        carros = carros.filter((vehiculo) => {
          return vehiculo.datos.Categoria.some(
            (categoria) =>
              normalizeString(categoria) === normalizeString(busqueda.categoria)
          );
        });
      }

      if (busqueda.marca) {
        carros = search(carros, busqueda.marca, 'Marca');
      }

      if (busqueda.anoMax) {
        carros = findItem(carros, 'Ano', {
          min: busqueda.anoMin || 0,
          max: busqueda.anoMax,
        });
      }

      if (busqueda.precioMax) {
        let max;
        let min;

        if (!isNaN(busqueda.precioMax)) {
          max = busqueda.precioMax;
        } else {
          max = busqueda.precioMax.includes('$')
            ? busqueda.precioMax &&
              parseInt(busqueda.precioMax.replace(/[.$]/g, ''))
            : busqueda.precioMax;
        }

        if (!isNaN(busqueda.precioMin)) {
          min = busqueda.precioMin;
        } else {
          min =
            busqueda.precioMin && busqueda.precioMin.includes('$')
              ? busqueda.precioMin &&
                parseInt(busqueda.precioMin.replace(/[.$]/g, ''))
              : busqueda.precioMin;
        }

        carros = findItem(carros, 'Precio_de_Venta', {
          min: min || 0,
          max,
        });
      }

      if (busqueda.modelo) {
        carros = search(carros, busqueda.modelo, 'Modelo');
      }

      if (busqueda.version) {
        carros = search(carros, busqueda.version, 'Version');
      }

      if (busqueda.transmition) {
        carros = search(carros, busqueda.transmition, 'Transmision');
      }

      if (busqueda.traction) {
        carros = search(carros, busqueda.traction, 'traccion');
      }

      if (busqueda.fuel) {
        carros = search(carros, busqueda.fuel, 'Combustible');
      }

      commit('SET_MARCAS', mapArray(carros, 'Marca'));
      commit('SET_MODELOS', mapArray(carros, 'Modelo'));
      commit('SET_VERSION', mapArray(carros, 'Version'));
      commit('SET_STOCK2', formatVehiculo(carros));
    },
    SEND_MAIL: async function ({dispatch}, mail) {
      if (mail.tipo === 'compra') {
        await dispatch('SEND_SELL_GENIO', mail);
      }
    },
    SEND_BUY_GENIO: async function ({commit, dispatch}, payload) {
      const data = {
        id: payload.vehiculo || payload.ppu,
        email: payload.correo,
        firstName: payload.nombre,
        lastName: payload.apellido,
        phone: payload.telefono,
        uid: payload.rut,
        birthDate: payload.birth,
        nationality: payload.nacionalidad,
        financingData: {
          employerName: payload.employer,
          admissionDate: payload.antiguedad,
          rent: payload.renta,
          initial: payload.pie,
          nameActivityType: payload.activity,
        },
      };

      if (!data.financingData.initial) {
        delete data.financingData;
      }

      try {
        await axios.post(`${VUE_APP_SELL_PROD_WEBHOOK_URL}`, data, {
          headers: {
            Authorization: VUE_APP_PROD_WEBHOOK_BEARER,
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        const error = {
          app_name: `Web Petersen`,
          view: `Formulario ${payload.type || payload.tipo}`,
          record_id: `Datos ${JSON.stringify(data)}`,
          context: `No se creo el Lead de venta en GENIO ${JSON.stringify(
            e.message
          )}`,
        };
        await dispatch('NOTIFY_ERROR', error);
      }
      commit('SET_CORREOSEND', true);
    },
    SEND_RENEWAL_GENIO: async function ({commit, dispatch}, payload) {
      const data = {
        idSaleCar: payload.vehiculo,
        registrationPlatePurchase: payload.ppu,
        year: payload.ano,
        brand: payload.marca.name,
        model: payload.modelo.name,
        version: payload.version.name,
        fuel: payload.fuel,
        transmission: payload.transmition.name,
        traction: payload.traction.name,
        mileage: payload.kilometraje,
        ownersNumber: payload.owner,
        lastMaintenance: payload.maintenance,
        expectedPrice: payload.price,
        firstName: payload.nombre,
        lastName: payload.apellido,
        phone: payload.telefono,
        email: payload.correo,
        uid: payload.rut,
        birthDate: payload.birth,
        nationality: payload.nacionalidad,
        financingData: {
          employerName: payload.employer,
          admissionDate: payload.antiguedad,
          rent: payload.renta,
          initial: payload.pie,
          nameActivityType: payload.activity,
        },
      };

      try {
        await axios.post(`${VUE_APP_RENEWAL_PROD_WEBHOOK_URL}`, data, {
          headers: {
            Authorization: VUE_APP_PROD_WEBHOOK_BEARER,
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        const error = {
          app_name: `Web Petersen`,
          view: `Formulario renovacion`,
          record_id: `Datos ${JSON.stringify(data)}`,
          context: `No se creo el Lead de compra en GENIO ${JSON.stringify(
            e.message
          )}`,
        };
        await dispatch('NOTIFY_ERROR', error);
      }

      commit('SET_CORREOSEND', true);
    },
    SEND_SELL_GENIO: async function ({commit, dispatch}, payload) {
      const data = {
        registrationPlate: payload.ppu,
        year: payload.ano,
        brand: payload.marca.name,
        model: payload.modelo.name,
        version: payload.version.name,
        fuel: payload.fuel,
        transmission: payload.transmition.name,
        traction: payload.traction.name,
        mileage: payload.kilometraje,
        ownersNumber: payload.owner,
        lastMaintenance: payload.maintenance,
        expectedPrice: payload.price,
        firstName: payload.nombre,
        lastName: payload.apellido,
        phone: payload.telefono,
      };

      try {
        await axios.post(`${VUE_APP_BUY_PROD_WEBHOOK_URL}`, data, {
          headers: {
            Authorization: VUE_APP_PROD_WEBHOOK_BEARER,
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        const error = {
          app_name: `Web Petersen`,
          view: `Formulario ${
            payload.consignment ? 'Consignaciones' : 'Compras'
          }`,
          record_id: `Datos ${JSON.stringify(data)}`,
          context: `No se creo el Lead de compra en GENIO ${JSON.stringify(
            e.message
          )}`,
        };
        await dispatch('NOTIFY_ERROR', error);
      }

      commit('SET_CORREOSEND', true);
    },
    SEND_CONTACT: async function ({dispatch, commit}, payload) {
      const data = {
        id: payload.vehiculo,
        firstName: payload.nombre,
        lastName: payload.apellido,
        phone: payload.telefono,
        email: payload.mail,
        comments: payload.comment,
        type: 'contacto',
      };

      try {
        await axios.post(`${VUE_APP_SELL_PROD_WEBHOOK_URL}`, data, {
          headers: {
            Authorization: VUE_APP_PROD_WEBHOOK_BEARER,
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        const error = {
          app_name: `Web Petersen`,
          view: `Formulario ${payload.type || payload.tipo}`,
          record_id: `Datos ${JSON.stringify(data)}`,
          context: `No se creo el Lead de venta en GENIO ${JSON.stringify(
            e.message
          )}`,
        };
        await dispatch('NOTIFY_ERROR', error);
      }

      commit('SET_CORREOSEND', true);
    },
    FIND_EXECUTIVE: async function ({commit}, payload) {
      if (!payload) return;
      const FIELDS = ['Nombre del Ejecutivo', 'Foto'];
      try {
        const {
          data: {records},
        } = await axios.get(
          `https://api.airtable.com/v0/appnSd3JFhZjhnMHp/tblmeHCjyUBaGppdW`,
          {
            ...CONFIG,
            params: {
              view: 'viwa8b2KbH2P29HbE',
              filterByFormula: `RECORD_ID()="${payload}"`,
              fields: [...FIELDS],
            },
          }
        );
        if (records.length) {
          const data = records[0];
          const executive = {
            name: data.fields[FIELDS[0]][0],
            photo: data.fields[FIELDS[1]][0].url,
          };

          commit('SET_EXECUTIVE', executive);
          return;
        }
        commit('SET_CALIFICATION_ALREADY', true);
      } catch (e) {
        throw new Error(e.response);
      }
    },
    GET_BRANDS: async function ({commit}, year) {
      if (!year) return;
      try {
        const {
          data: {brands},
        } = await axios.get(
          `${VUE_APP_HASURA_URL}/api/rest/vehicle/get-by-year/brand/${year}`
        );

        commit('SET_BRANDS', brands);
      } catch (e) {
        throw new Error(e.response);
      }
    },
    GET_MODELS: async function ({commit}, {year, brand}) {
      if (!year || !brand) return;
      try {
        const {
          data: {models},
        } = await axios.get(
          `${VUE_APP_HASURA_URL}/api/rest/vehicle/get-by-year/model/${year}/brand/${brand.id}`
        );

        commit('SET_MODELS', models);
      } catch (e) {
        throw new Error(e.response);
      }
    },
    GET_VERSIONS: async function ({commit}, {year, model}) {
      if (!year || !model) return;
      try {
        const {
          data: {versions},
        } = await axios.get(
          `${VUE_APP_HASURA_URL}/api/rest/vehicle/get-by-year/version/${year}/id_model/${model.id}`
        );

        commit('SET_VERSIONES', versions);
      } catch (e) {
        throw new Error(e.response);
      }
    },
    GET_ATTRIBUTES: async function ({commit}, {version, year}) {
      if (!version || !year) return;
      try {
        const {
          data: {attributes},
        } = await axios.get(
          `${VUE_APP_HASURA_URL}/api/rest/vehicle/get-by-year-and-version/year/${year}/version/${version.id}`
        );

        commit('SET_ATTRIBUTES', attributes);
      } catch (e) {
        throw new Error(e.response);
      }
    },
    GET_YEARS: async function ({commit, dispatch}) {
      const fields = airtableFieldConstruction(['fldGj7Nzh4iXNzNlN']);

      const SORT = [{field: 'fldGj7Nzh4iXNzNlN', direction: 'desc'}];
      try {
        const {
          data: {records},
        } = await axios.get(
          `https://api.airtable.com/v0/appnSd3JFhZjhnMHp/tbl80GZ2dSKrYUSN4?${fields}`,
          {
            ...CONFIG,
            params: {
              ...SORT,
            },
          }
        );

        const years = sortArray(
          records.map((record) => {
            return {
              name: record.fields['Año'],
              id: record.id,
            };
          }),
          'name',
          false
        );

        commit('SET_YEARS', years);
      } catch (e) {
        await dispatch('RETRY_GET_VALUE', 'GET_YEARS');
      }
    },
    SEND_EVALUATION: async function (
      {commit},
      {channel, feeling, rating, recommend, again, comment, id}
    ) {
      try {
        await axios.patch(
          `https://api.airtable.com/v0/appnSd3JFhZjhnMHp/tblmeHCjyUBaGppdW/${id}`,
          {
            fields: {
              Origen: [channel],
              'Cliente satisfecho': feeling,
              'Calificacion de atención': rating,
              'Nos recomendaria?': recommend,
              'Volverias hacer negocios?': again,
              'Recomendación del cliente': comment,
            },
          },
          {
            ...CONFIG,
          }
        );
        commit('SET_EVALUATION_SEND', true);
      } catch (e) {
        throw new Error(e.response);
      }
    },
    FIND_PORTRAIT_PHOTO: async function ({commit, dispatch}) {
      const fields = airtableFieldConstruction(['fld5vW2ZLJgpD8UUy']);

      try {
        const {
          data: {records},
        } = await axios.get(
          `https://api.airtable.com/v0/appnSd3JFhZjhnMHp/tblHO8SpJK7l2dppn?${fields}`,
          {
            ...CONFIG,
          }
        );
        const mapPhotos = records.map((record) => record.fields['Foto']);
        let photos = [];
        mapPhotos.forEach((p) => {
          photos = [...photos, ...p];
        });

        commit('SET_PHOTOS', photos);
      } catch (e) {
        await dispatch('RETRY_GET_VALUE', 'FIND_PORTRAIT_PHOTO');
      }
    },
    async findAirtableVehicle({commit}, id) {
      if (!id) return;
      const filter = filterAirtableConstructor(id, 'fldRJsNI3zp0hWNoX');
      const fields = airtableFieldConstruction([
        'fld5tRrvjbh7VPS1K',
        'fldAOsMv65eN7KzZA',
        'fldcT5f1oBf3uCQEu',
        'fldjhDR4uv2nnofU4',
        'fldZEorfYPxsbXcjh',
      ]);
      try {
        const {
          data: {records},
        } = await axios.get(
          `https://api.airtable.com/v0/appnSd3JFhZjhnMHp/tblMQoyoyP4oXVF6k?${fields}${filter}`,
          {
            ...CONFIG,
          }
        );
        commit('SET_VEHICLE_DETAIL', records[records.length - 1]);
      } catch (e) {
        throw new Error(e.response);
      }
    },
    async GET_PPU_INFO({commit, state}, ppu) {
      if (!ppu) return;

      try {
        const {data} = await axios.get(
          `${VUE_APP_HASURA_URL}/api/rest/vehicle/get-by-ppu/${ppu.toUpperCase()}`
        );

        const {match} = data;
        const resp =
          !match || Array.isArray(data)
            ? state.vehiclePpuDetail === undefined
              ? null
              : undefined
            : match;

        commit('SET_VEHICLE_PPU_DETAIL', resp);
      } catch (e) {
        commit('SET_VEHICLE_PPU_DETAIL', null);
        throw new Error(e.response);
      }
    },
    async NOTIFY_ERROR(/* eslint-disable no-unused-vars */ context, errorInfo) {
      try {
        await axios.post(
          `${VUE_APP_HASURA_URL}/frontend-notification/api/v1/frontend-notification-service`,
          errorInfo,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async GET_COMMENTS({commit, state}) {
      const fields = airtableFieldConstruction([
        'fldDD3ispsH4vZezo',
        'fldQxVhZRfJyy8mRM',
        'fld3tiQXYkck4R0WC',
        'fldnciaOYolzNGvxL',
      ]);

      try {
        const {
          data: {records},
        } = await axios.get(
          `https://api.airtable.com/v0/appnSd3JFhZjhnMHp/tbl4CrKsAxj0s3tY6?view=viwPsa6vRxdK1OaGs&${fields}`,
          {
            ...CONFIG,
          }
        );

        commit(
          'SET_TESTIMONY_DATA',
          [...state.testimonyData, ...records].map(
            (record) => new Testimony(record)
          )
        );
      } catch (e) {
        throw new Error(e.response);
      }
    },
    async GET_VID({commit, state}) {
      const fields = airtableFieldConstruction([
        'fldhGWJsdiqLeDiKf',
        'fld5vW2ZLJgpD8UUy',
      ]);
      try {
        const {
          data: {records},
        } = await axios.get(
          `https://api.airtable.com/v0/appnSd3JFhZjhnMHp/tblHO8SpJK7l2dppn?view=viw1UbVJAezORYKVB&${fields}`,
          {...CONFIG}
        );

        commit(
          'SET_TESTIMONY_DATA',
          [...state.testimonyData, ...records].map(
            (record) => new Testimony(record)
          )
        );
      } catch (e) {
        throw new Error(e.response);
      }
    },
  },
});
