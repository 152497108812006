import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import flags from '@/components/icons/flags/index.ts';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        title: '#0434AC',
        primary: '#FFFFFF',
        secondary: '#000000',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#0032a5',
        success: '#4CAF50',
        warning: '#F5F5F5',
      },
    },
  },
  lang: {
    locales: {es},
    current: 'es',
  },
  icons: {
    iconfont: 'md' || 'mdi' || 'fa',
    values: {
      ...flags,
    },
  },
});
