<template>
  <v-app>
    <router-view />
    <update-pwa />
  </v-app>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import UpdatePwa from '@/components/core/updatePwa';

export default {
  name: 'App',
  components: {UpdatePwa},
  data: () => ({
    tab: 0,
    mdAndDown: 'width: 35px; height: 35px',
    xlOnly: 'width: 55px; height: 55px',
    elevateOnScroll: false,
    hideOnScroll: true,
    drawer: false,
    shrinkOnScroll: true,
    extended: false,
    color: 'primary',
    showAppBar: true,
    tabValue: null,
    firstRender: true,
    countRender: 0,
    chickProportions: false,
  }),

  created() {
    this.FIND_AIRTABLE();
    this.FIND_PORTRAIT_PHOTO();
  },
  mounted() {
    if (window.location.href.includes('#compramos-tu-auto')) {
      const self = this;
      setTimeout(() => {
        self.SET_SCROLL(true);
      }, 2500);
    }
    window.addEventListener('top', this.gotoSelectedVendor, false);
  },
  beforeDestroy() {
    window.removeEventListener('top', this.gotoSelectedVendor, false);
  },
  methods: {
    ...mapActions(['FIND_AIRTABLE', 'FIND_PORTRAIT_PHOTO']),
    ...mapMutations(['SET_ACTIVEMOSTRAR', 'SET_SCROLL']),
    gotoSelectedVendor() {
      document.getElementById('top').scrollIntoView({behavior: 'smooth'});
    },
    goTo(link) {
      window.open(link, '_blank');
    },
  },
};
</script>
<style scoped></style>
