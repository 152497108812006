import dayjs from 'dayjs';

export class Testimony {
  constructor(data) {
    const {id, fields} = data;
    this.id = id;
    this.fields = fields;
  }

  get isVideo() {
    const {Video} = this.fields;
    return Boolean(Video && Video.length);
  }

  get isPhoto() {
    const {Video, Foto} = this.fields;
    return Foto && Foto.length && !Video;
  }

  get isComment() {
    const {Video, Foto} = this.fields;
    return !Video && !Foto;
  }

  get photo() {
    const {Foto} = this.fields;
    return Foto && Foto[0];
  }

  get video() {
    const {Video} = this.fields;
    return Video && Video[0];
  }

  get testimony() {
    const name = this.fields['Nombre & apellido cliente'][0];
    const rating = this.fields['Rating'].length;
    const comment = this.fields['¿Qué nos aconsejaría para mejorar?'];
    const createdTime = dayjs(this.fields['Created']).format('DD/MM/YYYY');
    return {
      name,
      rating,
      comment,
      createdTime,
    };
  }
}

export class TestimonyManager {
  constructor(testimonies) {
    this.testimonies = testimonies;
    this.currentIndices = {comment: 0, photo: 0, video: 0};
    this.pageSize = 20;
    this.patterns = [
      ['comment', 'photo', 'video'],
      ['video', 'photo', 'comment'],
      ['photo', 'comment', 'video'],
    ];
    this.lastTypeAdded = null;
  }

  getNextOfType(type) {
    if (type === this.lastTypeAdded && this.hasMoreThanOneTypeAvailable()) {
      return null;
    }

    const typeMap = {
      comment: 'isComment',
      photo: 'isPhoto',
      video: 'isVideo',
    };

    while (this.currentIndices[type] < this.testimonies.length) {
      const currentTestimony = this.testimonies[this.currentIndices[type]];
      if (currentTestimony[typeMap[type]]) {
        this.lastTypeAdded = type;
        this.currentIndices[type]++;
        return currentTestimony;
      }
      this.currentIndices[type]++;
    }
    return null;
  }

  hasMoreThanOneTypeAvailable() {
    const availableTypes = Object.keys(this.currentIndices).filter((type) => {
      const index = this.currentIndices[type];
      return (
        index < this.testimonies.length &&
        this.testimonies[index][`is${type[0].toUpperCase() + type.slice(1)}`]
      );
    });
    return availableTypes.length > 1;
  }

  getNextBatch() {
    let batch = [];
    let attempts = 0;

    while (batch.length < this.pageSize && attempts < this.pageSize * 3) {
      for (let pattern of this.patterns) {
        for (let type of pattern) {
          if (batch.length < this.pageSize) {
            const nextItem = this.getNextOfType(type);
            if (nextItem) {
              batch.push(nextItem);
            }
          }
        }
        if (batch.length === this.pageSize) {
          break;
        }
      }
      attempts++;
    }

    if (batch.length < this.pageSize && !this.hasMoreThanOneTypeAvailable()) {
      const remainingType = Object.keys(this.currentIndices).find(
        (type) => this.currentIndices[type] < this.testimonies.length
      );
      if (remainingType) {
        while (
          batch.length < this.pageSize &&
          this.currentIndices[remainingType] < this.testimonies.length
        ) {
          const nextItem = this.getNextOfType(remainingType);
          if (nextItem) {
            batch.push(nextItem);
          }
        }
      }
    }

    return batch;
  }
}
