// Exportaciones individuales de cada bandera
import Argentina from './Argentina.vue'
import Bolivia from './Bolivia.vue'
import Brasil from './Brasil.vue'
import Chile from './Chile.vue'
import Colombia from './Colombia.vue'
import CostaRica from './CostaRica.vue'
import Cuba from './Cuba.vue'
import Ecuador from './Ecuador.vue'
import ElSalvador from './ElSalvador.vue'
import Eua from './Eua.vue'
import Guatemala from './Guatemala.vue'
import Haiti from './Haiti.vue'
import Honduras from './Honduras.vue'
import Mexico from './Mexico.vue'
import Nicaragua from './Nicaragua.vue'
import Panama from './Panama.vue'
import Paraguay from './Paraguay.vue'
import Peru from './Peru.vue'
import Spain from './Spain.vue'
import Uruguay from './Uruguay.vue'
import Venezuela from './Venezuela.vue'

// Crear el objeto flags con cada componente de bandera
const flags = {
  flag_argentina: { component: Argentina },
  flag_bolivia: { component: Bolivia },
  flag_brasil: { component: Brasil },
  flag_chile: { component: Chile },
  flag_colombia: { component: Colombia },
  flag_costarica: { component: CostaRica },
  flag_cuba: { component: Cuba },
  flag_ecuador: { component: Ecuador },
  flag_elsalvador: { component: ElSalvador },
  flag_eua: { component: Eua },
  flag_guatemala: { component: Guatemala },
  flag_haiti: { component: Haiti },
  flag_honduras: { component: Honduras },
  flag_mexico: { component: Mexico },
  flag_nicaragua: { component: Nicaragua },
  flag_panama: { component: Panama },
  flag_paraguay: { component: Paraguay },
  flag_peru: { component: Peru },
  flag_spain: { component: Spain },
  flag_uruguay: { component: Uruguay },
  flag_venezuela: { component: Venezuela },
}

// Exportar el objeto completo `flags`
export default flags
