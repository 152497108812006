import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: 'stock',
    },
    {
      path: '/',
      name: 'Home',
      redirect: 'stock',
      component: () => import('@/layout/Index.vue'),
      children: [
        {
          path: '',
          name: 'petersen',
          component: () => import('./views/Home.vue'),
          children: [
            {
              path: '/cliente/:clienteId',
              name: 'resultado-cliente',
              components: {
                modal: () => import('./components/cliente/clienteDetalle.vue'),
              },
              props: {
                modal: (route) => ({
                  clienteId: Number(route.params.clienteId),
                }),
              },
            },
          ],
        },
        {
          path: '/stock',
          name: 'Stock',
          component: () => import('./views/Stock.vue'),
        },
        {
          path: '/stock/:vehiculoId',
          name: 'resultado-vehiculo',
          component: () => import('./views/DetalleVehiculo.vue'),
        },
        {
          path: '/financiamiento-automotriz',
          name: 'financiamiento',
          component: () => import('./views/Financiamiento.vue'),
        },
        {
          path: '/compramos-tu-auto',
          name: 'compramos-tu-auto',
          component: () => import('./components/compramosAuto.vue'),
        },
        {
          path: '/renovacion',
          name: 'renovacion',
          component: () => import('./components/compramosAuto.vue'),
          props: {
            renewal: true,
          },
        },
        {
          path: '/contacto',
          name: 'contacto',
          component: () => import('./components/contacto.vue'),
        },
        {
          path: '/consignaciones',
          name: 'vendemos-tu-auto',
          component: () => import('./components/consignacion.vue'),
        },
        {
          path: '/mision-vision',
          name: 'mision-vision',
          component: () => import('./views/MisionVision.vue'),
        },
        {
          path: '/testimonios',
          name: 'testimonios',
          component: () => import('./views/Testimony.vue'),
        },
        {
          path: '/clientes',
          name: 'clientes',
          component: () => import('./views/Clientes.vue'),
        },
        {
          path: '/feedback',
          name: 'feedback',
          component: () => import('./views/FeedBack.vue'),
        },
        {
          path: '/terminos-y-condiciones',
          name: 'terminos',
          component: () => import('./views/TermView.vue'),
        },
        {
          path: '/politicas-de-privacidad',
          name: 'privacidad',
          component: () => import('./views/PolicyView.vue'),
        },
      ],
    },
    {
      path: '*',
      component: () =>
        import(/* webpackChunkName: "*" */ './views/Error404.vue'),
    },
  ],
});

function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

getRoutesList(router.options.routes, 'https://automotrizpetersen.cl');

function getRoutesXML() {
  const list = getRoutesList(
    router.options.routes,
    'https://automotrizpetersen.cl'
  )
    .map((route) => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

getRoutesXML();

export default router;
