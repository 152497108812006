<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="updatePwa" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            {{ pwaUpdateMessageTitle }}
          </v-card-title>
          <v-card-text align="center">{{ pwaUpdateMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" @click.native="refreshApp">
              {{ pwaBtnText }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'updatePwa',
  data: () => ({
    pwaBtnText: 'Actualizar',
    pwaUpdateMessage:
      'Para visualizar los cambios. por favor Actualiza la pagina',
    pwaUpdateMessageTitle: 'Tenemos novedades!',
    updatePwa: false,
    refreshing: false,
    registration: null,
  }),
  mounted() {
    document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updatePwa = true;
    },
    refreshApp() {
      this.updatePwa = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
};
</script>

<style scoped></style>
