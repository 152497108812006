export function subtractNumber(array, number) {
  let items = array.toString().split('');

  const startIndex =
    number || items.length === 7 ? 1 : items.length === 8 ? 2 : 3;
  for (let i = startIndex; i < items.length; i++) {
    items[i] = '0';
  }
  return Number(items.join(''));
}

export function isWeb() {
  let web = true;
  if (!window.matchMedia('(display-mode: standalone)').matches) {
    const iOS = /iPad|iPhone|iPod|Android|webOS/.test(navigator.userAgent);
    // @ts-ignore
    const iOS2 =
      !!navigator.platform &&
      /iPad|iPhone|iPod|Android|webOS/.test(navigator.platform);

    web = !iOS && !iOS2;
  }

  return web;
}

export function resizeIconWidth() {
  // eslint-disable-next-line no-invalid-this
  if (this.$vuetify.breakpoint.xlOnly) {
    return '30';
  }
  return '25';
}

export function resizeIconHeight() {
  // eslint-disable-next-line no-invalid-this
  if (this.$vuetify.breakpoint.xlOnly) {
    return '30';
  }
  return '25';
}

export function airtableFieldConstruction(fields) {
  return fields.map((field) => `fields[]=${field}`).join('&');
}

export function filterAirtableConstructor(data, field) {
  return `&filterByFormula=AND(${field}='${data}')`;
}

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export function normalizeString(input) {
  if (!input) return;
  return input
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export function formatDate(date) {
  const data = date.split('-');

  return data[2] + '/' + data[1] + '/' + data[0];
}

export function fixDate(date) {
  if (date && date.includes('/')) {
    const dateSplitted = date.split('/') || '';

    return `${dateSplitted[1]}/${dateSplitted[0]}/${dateSplitted[2]}`;
  }
  return date;
}

export const subtitulos = [
  '- No compramos  vehículos con mas de 90.000kms',
  '- No compramos  vehículos chocados o reparados',
  '- No podemos pagar valor de mercado',
  '- Si ya tiene una oferta sería, la mejoraremos',
];

export function findClosestValue(targetValue, priceList) {
  if (!targetValue || !(priceList && priceList.length)) return null;

  let targetNumber;
  if (targetValue instanceof String) {
    targetNumber = parseInt(targetValue.replace(/[.$]/g, ''));
  } else {
    targetNumber = targetValue;
  }

  let result = priceList.reduce(
    (previousResult, currentPrice) => {
      let currentNumber = isNaN(currentPrice)
        ? parseInt(currentPrice.replace(/[.$]/g, ''))
        : currentPrice;

      let currentDifference = Math.abs(currentNumber - targetNumber);

      if (currentDifference < previousResult.difference) {
        return {price: currentPrice, difference: currentDifference};
      }
      return previousResult;
    },
    {price: '', difference: Infinity}
  );

  return result.price;
}
